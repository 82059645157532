import React from "react";
import Background from '../components/Background';

const HomePage = () => {
    return(
        <div className='home-page'>
            <Background/>
        </div>
    )
}

export default HomePage;